@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,800;0,900;1,400&display=swap');

h1{
 font-family: 'Poppins', sans-serif;
 font-weight: 900;
}

h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 18pt;
   }


   h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 17pt;
   }

   p{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 10pt;
   }

   label{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
   }

.products-table table{
    width: 100%;
margin-bottom: 2%;
   
}

.products-table table tr{
    padding-top: 2%;
    padding-bottom: 2%!important;
    
}

.products-table .active td{
    background-color: cornflowerblue!important;
    color: white;
}

.products-table  table tr th{
    width: 10%;
    padding:  1% 2%;
    background-color: white;
 
}
.products-table  table tr td{
    width: 12%;
    padding: 1% 2%;
    background-color: white;

}

.archive-table  table tr th{
    width: 11.5%;
    padding:  1% 2%;
    background-color: white;
 
}
.archive-table  table tr td{
    width: 11.5%;
    padding: 1% 1%;
    background-color: white;

}
.products-actions{
width: 100%!important;
padding: 1%;
background-color: white;
margin-bottom: 2%;

}

.products-actions a{
    margin-right: 1%;
    border: solid 1px rgb(105, 104, 104);
    padding: 1%;
    border-radius: 5px;
    color: black;
    float: left;
}

.products-actions a:hover{
    background-color: rgb(99, 96, 96);
    color: white;
    text-decoration: none;
}

.delete-button{
    padding: 1.3%;
    background-color: red;
    border: red solid 1px;float:left;
}

.delete-button:hover{
    background-color: rgb(150, 5, 5);
    border: rgb(150, 5, 5) solid 1px;float:left;
}

.align-button-right{
    float: right!important;
}
.add-product-colum{
    background-color: white;
    padding: 4% 2%;
    margin-bottom: 3%;
    
}

.colum-left{

    padding-right: 3%;
}

.add-product-colum input{
    width: 100%;
    padding:1%;
}

.rdw-editor-wrapper{
    border: solid 0.5px rgba(129, 128, 128, 0.137);
    border-radius: 5px;
}

.editorClassName{
    min-height: 100px;
    padding-left: 3%;
}

.add-product-colum select{
    width: 100%;
    padding:1.5%;
    margin-bottom: 3%;
}

.right-product-add-colom{
    padding: 7% 4%!important;
}

.right-product-add-colom span{
    color: gray;
}

.add-product-colum input[type="file"]{
    width: 75%!important;
    border: solid 1px black;
    border-radius: 5px;
}

.add-product-colum button{
    float: right;
    padding: 2%;
}
.product-images {
    padding: 0%;
    padding-left: 2%;
    
}

.product-images img{
    margin-top: 5%;
    width: 100%;
    border-radius: 5px;
}

.nothing-present{
    color: blue;
}
.input-price{
font-size: 12pt;
}

.input-price input{
    width: 95%;
    margin-left: 3%;
}

.add-product-colum input[type="checkbox"]{
    width: 2%;
    margin-right: 2%;
    
}

.variations-row button, .categories-products button{
    float: left!important;
    margin-top: 3%;
}

.last-col{
    background-color: transparent!important;
    margin-bottom: 10%;
}
.categories-products ul {
    padding-left: 0%;
}
.categories-products ul li{
list-style: none;
}

.main-save-button{
    float: right;
    padding:1.2%;
}

.error-message-product{
    float: right!important;
    padding: 1% 1%;
    margin-right: 5%;
    color: red;
    border: solid red 1px;
    border-radius: 5px;
}

.input-colom-profile{
    background-color: white;
    padding: 2%;
    border-radius: 5px;;
}

.input-colom-profile input,.input-colom-profile select{
    width: 100%;
    padding: 1%;
}

.input-colom-profile-50-50 input{
    padding: 2%;
}

.add-company-logo input[type="file"]{
    width: 75%!important;
    border: solid 1px black;
    border-radius: 5px;
}

.input-colom-profile button{
    float: right;
    padding: 2%;
}

.input-colom-profile-transparant img{
    width: 80%;
}

.input-colom-profile-50-50 select{
    width: 100%;
    padding: 2.5%;
}
.timeslots label{
    font-weight: 800;
}
.timeslots ul{
    padding-left: 0%;
}
.timeslots ul li{
    list-style: none;
    border-bottom: solid black 1px;
    padding:3%;
    padding-bottom: 4%;
    padding-top: 4%;
}

.timeslots button{
    text-align: right;
    background-color: transparent;
    border: solid 1px red;
    float: right;
    color: red;
}

.timeslots button:hover{
    color: white;

    background-color: red;
    border: solid 1px red;
}

#main-logo{
    width: 60%;
    margin-left: 20%;
}

.item-icon-wrapper{
    width: 15px;
    margin-right: 18px;
}

.main-navbar .navbar {
    height: 5.75rem;
}

.message-table{
    margin-bottom: 5%;
}

.message-table table{
    width: 102.5%;
    margin-left: -1.4%;
   
}

.message-table table tr{
    padding-top: 2%;
    padding-bottom: 2%!important;
    
}

.message-table .active td{
    background-color: cornflowerblue!important;
    color: white;
}

.message-table  table tr th{
    width: 25%;
    padding: 2%;
    background-color: white;
 
}
.message-table  table tr td{
    width: 20%;
    padding: 2%;
    background-color: white;

}
.button-list li{
  margin-bottom: 4%;

}
.button-list button{
    float: right!important;
    margin-top: 0%;
    border: solid 1px red;
    background-color: red;
}

.button-list button:hover{
    color: white;

    background-color: rgb(145, 7, 7);
    border: solid 1px rgb(145, 7, 7);
}

.button-onimage button{
    margin-top: 5%;
    position: absolute;
    float: right;
    margin-left: 63%;
    margin-top: 10%;
    z-index: 99;
}

.add-button{margin-top: 3%;}

.login-col{
    padding: 15% 10%;
    background-color: white;
}

.add-button-settings{
    background-color: #28654B!important;
    border: #28654B 1px solid!important;
}

.add-button-settings:hover{
    background-color: #1b4432!important;
}

.login-col img{
    width: 25%;
}

.login-col h3{
    margin-top: 8%;
    color:black
}

.login-col p{
    margin-bottom: 3%;
}

.login-col input{
    width: 90%;
    margin-left: 3%;
    border-radius: 50px;
    border: 1px grey solid;
}

.login-col button{
    width: 50%;
    background-color: #032341;
    padding: 2%;
    border-radius: 50px;
    margin-top: 4%;
    border:none;
    color: white;
}

.login-col button:hover{
    background-color: #129DEB;
}

.sign-on-col{
    padding: 15% 10%;
    background-color: white;
}
.sign-on-col img{
    width: 25%;
    margin-left: 37.5%;
}

.sign-on-col h3{
    margin-top: 8%;
    color:black;
    text-align: center;
}

.sign-on-col p{
    margin-bottom: 3%;
    text-align: center;
}

.sign-on-col input{
    width: 100%;
    margin-left: 3%;
    border-radius: 50px;
    border: 1px grey solid;
}

.sign-on-col button{
    width: 50%;
    background-color: #47B362;
    padding: 2%;
    border-radius: 50px;
    margin-top: 4%;
    border:none;
    color: white;
}

.sign-on-col button:hover{
    background-color: #3d9653;
}

.login-image-bg{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/mainimage1.img_-1024x576.jpg?alt=media&token=af69e4e2-3421-493f-9d52-42a249f0737a");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sign-on-button{
    width: 50%;
    background-color: #47B362;
    padding: 2% 5%;
    border-radius: 50px;
    margin-top: 10%!important;
    border:none;
    color: white;
    margin-left: 34%;
}

.ck-content{
    min-height: 200px;
}

.descript-undertitle{
    width: 100%;
    float: left;
    margin-top: 2%;
    margin-bottom: 5%;
    font-style: italic;
}

.sign-on-button:hover{
    background-color: #3d9653;
    text-decoration: none;
    color:white;
}

.complete-row{
    margin-top: 5%;
}

.logout-button{
    position: absolute;
      bottom: 0;
      left: 0;
    font-weight: bold;
    padding: .9375rem 1.5625rem;
    background-color: #129DEB;
    width: 100%;
    color: white;
  }

  .logout-button:hover{
    background-color: #0d78b6;
    cursor: pointer;
  }

  .sign-on-col select{
    padding: 10px;
    margin-top: 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
    cursor: auto;
    border-radius: 50px;
    width: 100%;
    margin-left: 3%;
    margin-bottom: 5%;

  }

  .input-check{
      width: 2%!important;
      float: left;
    margin-top: 4.5%!important;

  }

  .input-check-label{
    width: 93%!important;
    float: right;
   font-size: 9pt;
   font-weight: 400;
  }

  .sign-on-col h4{
      font-weight: 800;
      color: black;
  }

.input-check-span{
margin-top: -3%!important;
}

.sing-on-button{
    width: 50%!important;
    margin-left: 25%;
}

.error-message{
    width: 100%;
    padding: 3%;
    border: solid red 1px;
    margin-top: 3%;
    border-radius: 5px;
    color: red;
    padding-top: 5%;
    text-align: center;
}

.add-variation{
    width: 100%;
    padding: 5% 4%!important;
    margin-top: 13%!important;
}

.impression-picture{
    width: 100%;
    margin-top: 5%;
}

.changed-row{
    background: black;
    position: fixed;
    display: inline;
    width: 85%!important;
    height: 51px!important;
    bottom: 0px!important;
    padding: 1% 1%;
    margin-left:-2%;
    z-index: 999;
}

.changed-col{
   
}

tr{
    border-bottom: rgba(0, 0, 0, 0.24) 1px solid; 
}

.changed-col p{
    color: white;
    float: left;
    width: 80%;
    margin-top: 0%!important;
}

.changed-button{
    float: right;
    width: 10%;
    margin-right: 3.5%;
  margin-top: -0.7%!important;
}

.input-field-request{
    width: 100%;

}


.input-field-request label{
    margin-top: 5%!important;
}

.input-field-request input,.input-field-request select{
    width: 100%;
    padding: 1.5% 2%;
    margin-top: 2%;
}

.request-form h3{
    margin-top: 5%;
    margin-bottom: -3%;
}

.input-field-request h5{
    margin-top: 5%;
    margin-bottom: -2%;
}


.input-field-request label input[type="checkbox"]{
float: left;
width: 10%;
margin-top: 1%;
margin-left: -3%;
}

.overview-screen{
    position: fixed;
    width: 40%;
    min-height: 10px;
}

.overview-screen ul{
    list-style: none;
    padding-left: 0%;
}

.overview-screen ul li{
    background-color: white;

    width: 100%;
    padding: 2%;
}

.next-button-form{
    float: right;
    width: 20%;
    border: none;
    border-radius: 50px;
    background-color: #032341;
    color:white;
    padding: 1% 2%;
    margin-top: 4%;
}

.submit-button-form{
    float: left;
    width: 50%;
    border: none;
    border-radius: 50px;
    background-color: #032341;
    color: white;
    padding: 2% 3%;
    margin-top: 5%;

    border: 0!important;
}

.not-active, .not-active h3{
    opacity: 0.5;
}

.new-input-field{
    margin-top: 3%;
}

.create-space{
    width: 100%;
    float: left;
    padding: 4%;
    height: 100px;
    margin-bottom: 5%;
    color: transparent;
}

.request-detail-8{
    background-color: white;
    padding:2%;

}

.request-detail-row{
    width: 100%;
    

}

.request-detail-row hr{
    width: 100%;
    float: left;
    margin-top: 2%;
}

.request-detail-col-left{
width: 20%;
float: left;
}

.request-detail-col-right{
    width: 80%;
    float: right;
}
.request-detail-4{
    padding-left:2%;
}

.requests-block,.price-block{
    background-color: white;
    margin-bottom: 2%;
    padding:5%;
}


.align-text-right{
    text-align: right;
}

.ck.ck-editor{
   float: left;
   margin-top: 2%;
   width: 100%;
}

.thank-you-block{
    background-color: white;
    border-radius: 14px;
    padding: 4% 4% 1% 4%;
    margin-bottom: 3%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.firstblock-thankyou,.secondblock-thankyou,.thirdblock-thankyou{
    font-size: 12pt;
}

.firstblock-thankyou::before{
    display: inline-block;
    content: ' ';

    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    padding-right: 60px;
    background-repeat: no-repeat;
    padding-top: 20px;
    vertical-align: text-top;

}

.thank-you-block p{
    margin-left: 60px;
    margin-top: -5%;
}

.secondblock-thankyou::before{
    display: inline-block;
    content: ' ';
 
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    padding-right: 60px;
    background-repeat: no-repeat;
    padding-top: 20px;
    vertical-align: text-top;
}

.thirdblock-thankyou::before{
    display: inline-block;
    content: ' ';

    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    padding-right: 60px;
    background-repeat: no-repeat;
    padding-top: 20px;
    vertical-align: text-top;
}

.left-colum-thank-you img{
    width: 50%;
    margin-left: 25%;
}

.thank-you-detail h2{
    color:black;
    font-size: 22pt;

}
.right-title h2{
    margin-bottom: 10%;
}

.thank-you-detail h4{
    color:black;
    margin-top: -2%;
    margin-bottom: 8%;
}


.thank-you-col{
    margin-top: 10%;
}

.MuiFormControlLabel-root{
    width: 100%;
    margin-top:0%!important;
    padding:0%!important
}

.checkbox label{
margin-top: 0%!important;
margin-bottom: 0%!important;
}
.checkbox{
    padding-top:2%!important;
}

.dirix-default-button{
    background-color: #032341;
    width: 18%;
    padding: 0.8%;
    color: white;
    border-radius: 50px;
    border:none;
    float: right;
    text-align: center;
}

.dirix-default-button:hover{
    background-color:#094c8a;
    color:white;
}

.small-button{
width: 30%!important;
padding:2%;
}

.price-block textarea{
    width: 100%;
    min-height: 10vh;
    margin-top: 5%;
}



.request-detail-col-right input,.request-detail-col-right select{
width: 80%;
padding: 2% 3%;
border-radius: 50px;
border:1px black solid
}

.small-input input{
    width: 80%;
}

.administration-request{
    float: right;
    width: 28%;
    margin-top: 2%;
}

.map-col {
  
    margin-top: 13%;
    margin-bottom: 30%!important;
    width: 100%;
    margin-left: -3%;
    padding-top: 8%;

}

.new-table{
    border: red 2px solid;
border-radius: 20px;
margin-bottom: 3%;
}

.new-table h3{
    width: 102%;
    margin-left: -1%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1% 2%;
    background-color: red;
    color:white;
}

.running-table{
    border: orange 2px solid;
border-radius: 20px;
margin-bottom: 3%;
}

.running-table h3{
    width: 102%;
    margin-left: -1%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1% 2%;
    background-color: orange;
    color:white;
}

.done-table{
    border: green 2px solid;
border-radius: 20px;
margin-bottom: 3%;
}

.done-table h3{
    width: 102%;
    margin-left: -1%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1% 2%;
    background-color: green;
    color:white;
}
.main-sidebar, .main-navbar nav{
    background-color: #032341!important;
}

#main-logo {
    width: 36%;
    margin-left: -20%;
    position: absolute;
    margin-top: -4%;
}

.main-sidebar .nav .nav-item .nav-link{
    color:white!important
}

.main-sidebar .nav .nav-item .nav-link:hover{
    color:#032341!important;
}

.main-sidebar .nav .nav-item .nav-link.active{
    color:#032341!important;
    font-weight: bold;
}

.request-form,.overview-screen{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.2);
}
.overview-screen{
    padding:1%;
}


.mobile-menu{
    display: none;
}

.header-section{
    background-color: #032341!important;
    margin-left: -3%!important;
    padding-left: 22.5%;
padding-right: 20%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 3%;
}

.header-section .logo-section img{
    width: 60%;
}
.menu-section{
    padding-top: 0.5%;
    text-align: right;
}

.menu-section a{
    color: white;

    padding-bottom: 1%;
    border-bottom: #129DEB 1px solid;
}

.search-bar{
    width: 20%;
    border-radius: 50px;
    padding:0.5% 0.5% 0.5% 2%;
    margin-left: 78%;
    border:1px rgb(139, 139, 139) solid;
    margin-bottom: 1%;
    position: absolute;
    margin-top: -4%;
}

#icon{
    background-image:url(https://firebasestorage.googleapis.com/v0/b/f-fort-tool-2.appspot.com/o/search-icon.svg?alt=media&token=8147535a-4eb3-4945-9f9a-052676703cdf); 
    background-repeat: no-repeat; 
    background-position: 260px 10px;
    background-size: 20px;
    border-right: solid 1px black!important;
}

.faq-col{

    border-radius: 19px;
    padding: 2.5% 4% 1% 4%;
    margin-bottom: 2%;
  
}

.faq-col:hover{
    opacity: 0.8;
    cursor: pointer;
}

.faq-col h5{
    float: left;
    color: black;
    font-weight: 500;
    width: 90%;
    font-size: 12pt;
}


.faq-col i{
    float: right;
    margin-top: 0.5%;
    color: black;
    font-weight: bold;
    width: 2%;
}

.faq-row-para{
    width: 100%;
    float: left;
}

.request-form-choose{
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding-top: 1%;
}

.request-form-choose h2{
font-size: 15pt;

}

.add-asn{
    width: 40%;
    background-color: #119CEA;
    border-radius: 50px;
    padding: 2%;
    border:none;
    color:white;
}

.add-asn:hover{
    opacity: 0.8;
}

.request-form p{
margin-top: 4%;
}

.disabled{
    opacity: 0.8!important;
    cursor: not-allowed;
}

.material-icons{
font-size: 11pt!important;
text-align: center;

}

.status-block section{
    max-height: 400px;
    overflow-y: scroll;
}
.text-area-backend-form{
    width: 80%;
    min-height: 100px;
}

.map-frontend{
    position: absolute;
    z-index: 0;
}


.margin-top-gegevens{
    margin-top: 70%!important;
}

.margin-top-gegevens i{
margin-bottom: 5%;
}

.side-block-frontend-form{
    background-color: white;
  
    margin-bottom: 3%;
  
    border-radius: 5px;
    padding: 4%;
    float: left;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.side-block-frontend-form ul{
    padding-left: 0%;
}

.side-block-frontend-form ul li{
list-style: none;
}
.gegevens-row{
    padding-left: 5%;
    position: fixed;
    width: 40%;
    right: 180px;
    top: 132px;
}
.gegevens-row h3{
    margin-left: -3%!important;
}

.is-active .faq-col{
    padding: 0%;
    margin-bottom: 7%;
    padding-left: 1%;
    min-height: 29px;
    border-bottom: solid gray 1px;
    border-radius: 0px;
    margin-top: 7%;
    padding-bottom: 10%!important;
    padding-right: 1%;
}

.faq-row-para div p ul {
    margin-top: 7%;
}
.dirix-button{
    margin-bottom: 2%;
}

.archive-button-table{
    background-color: transparent;
    float: right;
    color:red;
    border-radius: 50px;
    border:none;
    padding: 3% 6%;
    margin-top: -4%;
}

.margin-top-location{
    margin-top: 5%!important;
}

.location-table {
    width: 100%;
    margin-top: 75%!important;

}

.smaller-button{
    padding: 1% 2%;
}

.reset-button{
    background-color: #032341;
    width: 24%;
    padding: 1% 4%;
    color: white;
    border-radius: 50px;
    border: none;
    float: left;
    text-align: center;
    MARGIN-TOP: 2%;
    margin-right: 75%;
    margin-bottom: 4%;
}

.location-block{
    height: 25rem;
}


.location-block {
    width: 90%;
}
