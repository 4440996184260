@media only screen and (max-width: 500px){

    .mobile-menu,.desktop-menu{
        display: none;
    }
    .products-actions{
        width: 105%!important;
    padding: 4%;
    background-color: white;
    margin-bottom: 2%;
    margin-right: -2%!important;
    }

    .complete-row{
        margin-top: 45%;
    }

    .sign-on-button{
        width: 50%;
        background-color: #47B362;
        padding: 2% 5%;
        border-radius: 50px;
        margin-top: 10%!important;
        border:none;
        color: white;
        margin-left: 30%;
    }

    .input-check {
        width: 4%!important;
        float: left;
        margin-top: 7%!important;
    }
    .input-check-label {
        width: 89%!important;
        float: right;
        font-size: 9pt;
        font-weight: 400;
    }
    

    .products-actions a {
        margin-right: 1%;
        border: solid 1px rgb(105, 104, 104);
        padding: 4%;
        border-radius: 5px;
        color: black;
        float: left;
    }

    .main-save-button {
        float: right;
        padding: 5.5%;
    }

    .add-product-colum input[type="file"] {
        width: 100%!important;
        border: solid 1px black;
        border-radius: 5px;
        padding: 7%;
    }

    .add-product-colum button {
        float: right;
        padding: 4%;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 8%;
    }

    .product-images{
        width: 40%;
        float:left;
        margin-left: 2.5%;
    }

    .input-price input {
        width: 90%;
        margin-left: 3%;
    }

    .add-product-colum input[type="checkbox"] {
        width: 13%;
        margin-right: 2%;
        float: left;
        margin-top: 1%;
    }
}