@media only screen and (max-width: 1441px) {

   

    .changed-row{
        background: black;
        position: fixed;
        display: inline;
        width: 85%!important;
        height: 51px!important;
        bottom: 0px!important;
        padding: 1% 1%;
        margin-left:-2%;
    }
    
    .changed-col{
       
    }
    
    .changed-col p{
        color: white;
        float: left;
        width: 80%;
        padding-top: 0.2%;
    }

    .map-col{
        margin-top: 20%;
        margin-bottom: 40%!important;
        width: 100%;
        margin-left: -3%;
        padding-top: 8%;
    }
    
    .changed-button{
        float: right;
        width: 10%;
        margin-right: 3.5%;
      margin-top: -0.4%!important;
    }
    
    .running-table h3{
        width: 103%;
        margin-left: -1.4%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 1% 2%;
        background-color: orange;
        color:white;
    }
    .new-table h3{
        width: 103%;
        margin-left: -1.4%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 1% 2%;
        background-color: red;
        color:white;
    }
    .done-table h3{
        width: 103%;
        margin-left: -1.4%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 1% 2%;
        background-color: green;
        color:white;
    }
    

}